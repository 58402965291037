<template>
  <div id="wallet-container">
    <!-- <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">钱包管理</span>
      </template>
      <template #right>
        <van-icon @click="onClickMore" name="ellipsis" size="2em" color="#ffffff"></van-icon>
      </template>
    </van-nav-bar> -->

    <div class="money-container">
      <div class="left">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" class="avatar" />
        <div>
          <p>Mr Huang</p>
          <p>DC001</p>
        </div>
      </div>
      <div class="credit">旦巢信用：782</div>
      <div class="account-money">
        <div class="account-item">
          <div>
            <p>
              账号余额
              <van-icon name="eye-o" color="#1989fa" />
              <van-icon name="question-o" color="#1989fa" />
            </p>
            <p class="account-text">5698.20</p>
          </div>
          <div>
            <p class="recharge">充值</p>
            <p class="cash-out">提现</p>
          </div>
        </div>
        <div class="account-item">
          <div>
            <p>
              预存租金
              <van-icon name="eye-o" color="#1989fa" />
              <van-icon name="question-o" color="#1989fa" />
            </p>
            <p class="account-text">17356.20</p>
          </div>
          <div>
            <div class="cash-out-item">
              <p class="recharge">充值预存</p>
              <p class="cash-out redColor">预存提现</p>
              <p class="cash-out">缴纳房租</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bill-box" style="margin-top: 100px">
      <van-tabs v-model="active">
        <van-tab title="所有费用">
          <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/cny.png" class="zhu-img" />
              <div class="bill-des">
                <p>转账-来自妹妹</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r green">+1680.00</div>
          </div>
        </van-tab>
        <van-tab title="支出">
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/cny.png" class="zhu-img" />
              <div class="bill-des">
                <p>转账-来自妹妹</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r green">+1680.00</div>
          </div>
          <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
        </van-tab>
        <van-tab title="收入">
          <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/cny.png" class="zhu-img" />
              <div class="bill-des">
                <p>转账-来自妹妹</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r green">+1680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
           <div class="bill-item">
            <div class="bill-item-l">
              <img src="../assets/icon/rent.png" class="zhu-img" />
              <div class="bill-des">
                <p>缴纳房租</p>
                <p class="time">2020-10-01 19:32</p>
              </div>
            </div>
            <div class="bill-item-r">-2680.00</div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <!-- <div class="money-box">
      <div class="title">
        <div class="color"/>
        <div class="text">钱包管理</div>
      </div>
      <div class="item-box">
        <div class="money-item">
          <div class="img-box">
            <img src="../assets/icon/cny.png"/>
          </div>
          <div class="number-box">￥5860.00</div>
          <div class="button-box">
            <van-button size="mini" text="充值" color="#00c468"/>
            <van-button size="mini" class="withdrawal-button" text="提现" color="#efefef"/>
          </div>
        </div>
        <div class="fraction-item">
          <div class="img-box">
            <img src="../assets/icon/medal.png">
          </div>
          <div class="number-box">760</div>
          <div class="button-box">
            <van-button size="mini" text="信用分详情" color="#26a5f9"/>
          </div>
        </div>
        <div class="deposit-item">
          <div class="img-box">
            <img src="../assets/icon/deposit.png">
          </div>
          <div class="number-box">￥5200.00</div>
          <div class="button-box">
            <van-button size="mini" text="预存租金" color="#2769fc"/>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="card-box">
      <div class="title">
        <div class="color"/>
        <div class="text">银行卡</div>
        <div class="button-box">
          <div class="img-box">
            <img src="../assets/icon/plus.png"/>
          </div>
          <div class="img-box">
            <img src="../assets/icon/reduce.png"/>
          </div>
        </div>
      </div>
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide style="width: 60%" v-for="(i,index) in cardItems" :key="index">
          <div class="card-item" :style="'background-color:'+i.color">
            <div class="bank">{{ i.bank }}</div>
            <div class="type">{{ i.type }}</div>
            <div class="number">****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{{ i.number }}</div>
          </div>
        </swiper-slide>
      </swiper>
    </div> -->

    <!-- <div class="other-box">
      <div class="title">
        <div class="color" />
        <div class="text">其他服务</div>
      </div>
      <div class="content">暂无更多服务</div>
    </div> -->

    <!-- <div class="bill-box">
      <div class="title">
        <div class="color" />
        <div class="text">账单</div>
        <div class="more-button">
          <div class="text">更多</div>
          <div class="img-box">
            <img src="../assets/icon/right.png" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <div class="item">流水号</div>
          <div class="item">类别</div>
          <div class="item">交易时间</div>
          <div class="item">总金额</div>
        </div>
        <div class="row" v-for="i in billItems" :key="i.id">
          <div class="item" style="color: #26a5f9">{{ i.id }}</div>
          <div class="item">{{ i.type }}</div>
          <div class="item">{{ i.time }}</div>
          <div class="item" style="color: #dc143c">{{ i.money }}</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "wallet",
  data() {
    return {
      active: 0,
      swiperOption: {
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
        },
      },
      cardItems: [
        {
          bank: "中国建设银行",
          type: "储蓄卡",
          number: "8888",
          color: "#0083f6",
        },
        {
          bank: "中国工商银行",
          type: "储蓄卡",
          number: "6666",
          color: "#ff0038",
        },
      ],
      billItems: [
        {
          id: 202009758,
          type: "缴纳房租",
          time: "2020-10-01",
          money: 2680.0,
        },
        {
          id: 202009757,
          type: "缴纳房租",
          time: "2020-09-01",
          money: 2600.0,
        },
        {
          id: 202009756,
          type: "缴纳房租",
          time: "2020-08-01",
          money: 2720.0,
        },
        {
          id: 202009755,
          type: "缴纳房租",
          time: "2020-07-01",
          money: 2650.0,
        },
        {
          id: 202009754,
          type: "缴纳房租",
          time: "2020-06-01",
          money: 2620.0,
        },
      ],
    };
  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickMore() {
      this.$router.push("/payment-management");
    },
  },
};
</script>

<style scoped>
#wallet-container {
  min-height: 100%;
  background-color: #f8f8f8;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #ffffff;
  font-weight: bold;
}

#wallet-container > .money-box {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2%;
  margin: 2%;
}

#wallet-container > .money-box > .title {
  display: flex;
}

#wallet-container > .money-box > .title > .color {
  background-color: #dc143c;
  width: 5px;
  border-radius: 5px;
}

#wallet-container > .money-box > .title > .text {
  margin-left: 7px;
}

#wallet-container > .money-box > .item-box {
  display: flex;
  margin: 7px 0;
}

#wallet-container > .money-box > .item-box > * {
  width: 25%;
  margin: 0 auto;
}

#wallet-container > .money-box > .item-box .button-box {
  width: 90%;
  margin: 0 auto;
}

#wallet-container > .money-box >>> .van-button {
  width: 100%;
}

#wallet-container > .money-box > .item-box > * > .img-box {
  width: 50%;
  margin: 0 auto;
}

#wallet-container > .money-box > .item-box > * > .img-box > img {
  width: 100%;
}

#wallet-container > .money-box > .item-box > * > .number-box {
  text-align: center;
  margin: 7px 0;
  font-size: 18px;
  font-weight: bold;
}

#wallet-container > .money-box .withdrawal-button {
  margin: 7px 0;
}

#wallet-container > .money-box .withdrawal-button >>> .van-button__text {
  color: #00c468;
}

#wallet-container > .money-box > .item-box > .money-item > .button-box > * {
  display: block;
}

#wallet-container > .card-box {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2%;
  margin: 2%;
}

#wallet-container > .card-box > .title {
  display: flex;
}

#wallet-container > .card-box > .title > .color {
  background-color: #26a5f9;
  width: 5px;
  border-radius: 5px;
}

#wallet-container > .card-box > .title > .text {
  margin-left: 7px;
}

#wallet-container > .card-box > .title > .button-box {
  margin-left: auto;
  display: flex;
}

#wallet-container > .card-box > .title > .button-box > .img-box {
  width: 1em;
  margin: auto 7px;
}

#wallet-container > .card-box > .title > .button-box > .img-box > img {
  width: 100%;
  display: block;
  margin: auto 0;
}

#wallet-container > .card-box > .swiper {
  margin: 7px 0;
}

::v-deep .swiper-slide {
  margin: auto;
}

/* 上一张 */
::v-deep .swiper .swiper-wrapper .swiper-slide-prev .card-item {
  height: 5.5em !important;
  transition: height 0.3s linear;
}
/* 下一张 */
::v-deep .swiper .swiper-wrapper .swiper-slide-next .card-item {
  height: 5.5em !important;
  transition: height 0.3s linear;
}

/* 中间的图片 */
::v-deep .swiper .swiper-wrapper .swiper-slide-active .card-item {
  /*height: 6em !important;*/
}

#wallet-container > .card-box .card-item {
  padding: 5%;
  border-radius: 5px;
  box-shadow: #646566 3px 3px 5px 1px;
  color: #ffffff;
  height: 7em;
  width: 11em;
  margin: 7px;
  position: relative;
}

#wallet-container > .card-box .card-item > .bank {
  font-size: 18px;
  font-weight: bold;
}

#wallet-container > .card-box .card-item > .number {
  position: absolute;
  bottom: 7px;
}

#wallet-container > .other-box {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 2%;
  margin: 2%;
}

#wallet-container > .other-box > .title {
  display: flex;
}

#wallet-container > .other-box > .title > .color {
  background-color: #00c468;
  width: 5px;
  border-radius: 5px;
}

#wallet-container > .other-box > .title > .text {
  margin-left: 7px;
}

#wallet-container > .other-box > .content {
  margin: 7px 0;
  text-align: center;
  font-size: 18px;
  color: #646566;
}

#wallet-container > .bill-box {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 2%;
  margin: 2%;
}

#wallet-container > .bill-box > .title {
  display: flex;
}

#wallet-container > .bill-box > .title > .color {
  background-color: #fdc53c;
  width: 5px;
  border-radius: 5px;
}

#wallet-container > .bill-box > .title > .text {
  margin-left: 7px;
}

#wallet-container > .bill-box > .title > .more-button {
  margin-left: auto;
  display: flex;
}

#wallet-container > .bill-box > .title > .more-button > .text {
  color: #646566;
}

#wallet-container > .bill-box > .title > .more-button > .img-box {
  margin: auto 0;
  display: block;
}

#wallet-container > .bill-box > .title > .more-button > .img-box > img {
  height: 1em;
  display: block;
  margin: auto 0;
}

#wallet-container > .bill-box > .content > * {
  margin: 7px 0;
  color: #646566;
  font-size: 12px;
}

#wallet-container > .bill-box .title {
  display: flex;
}

#wallet-container > .bill-box .title > .item {
  width: 25%;
  text-align: center;
}

#wallet-container > .bill-box .row {
  display: flex;
}

#wallet-container > .bill-box .row > .item {
  width: 25%;
  text-align: center;
}
</style>
<style scoped="scoped" lang="less">
@import "../assets/less/vantChange.less";
.money-container {
  position: relative;
  height: 15rem;
  background: @yellowColor;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-top: 40px;
    .avatar {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  .credit {
    height: 18px;
    margin-right: 15px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
    color: #fff;
    border: 5px;
    border-radius: 5px;
    margin-top: 75px;
    font-size: 12px;
  }

  .account-money {
    width: 80%;
    padding: 25px 30px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    .account-item {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .account-text {
        font-weight: 700;
      }
      p {
        margin: 0px;
        padding: 0px;
      }
      p.recharge {
        color: #fff;
        background-color: @orangeColor;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 4px;
        font-size: 12px;
      }
      p.cash-out {
        margin-top: 5px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 4px;
        border: 1px solid @orangeColor;
        font-size: 12px;
        color: @orangeColor;
      }
      p.redColor {
        border-color: @redColor;
        color: @redColor;
        position: absolute;
        right: 100px;
        width: 48px;
      }
      .cash-out-item {
        position: relative;
      }
    }
  }
}

.bill-box {
  .bill-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    padding-top: 20px;
    .bill-item-l {
      display: flex;
      .zhu-img{
        width: 25px;
        height: 25px;
      }
      .bill-des{
        margin-left: 5px;
        p{
          margin: 0px;
          padding: 0px;
          font-size: 12px;
        }
        p.time{
          color: #969696;
        }
      }
    }
    .bill-item-r{
      color: @redColor;
    }
    .bill-item-r.green{
      color: @greenColor;
    }
  }
}
</style>